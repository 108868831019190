import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img className="App-logo" src={`${process.env.PUBLIC_URL}/espoox.png`} alt="EspooX Logo" />
        <h1 className="logo-title">ESPooX</h1>
        <p className="logo-slogan">The Village of Innovation</p>
        <p className="comming-slogan">Coming Soon..</p>
      </header>
    </div>
  );
}

export default App;
